<template>
<div>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">View store</h5>
      <div v-if="!isLoading">
        <div class="card card-height-100">
          <div class="card-body">
            <div class="row gy-3 justify-content-between align-items-start">
              <div class="col-10 col-sm-8">
                <div class="friendly-id mb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard">
                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                    <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                    </svg>
                    {{store.friendly_id}}
                </div>
                <div class="row g-3">
                    <div class="col-12 col-md-6">
                        <label class="form-label fw-bold">Name</label>
                        <div>{{store.title}}</div>
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="form-label fw-bold">Status</label>
                        <div><span v-html="statusBadge(store.status)"></span></div>
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="form-label fw-bold">Type</label>
                        <div>{{store.store_type.title}}</div>
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="form-label fw-bold">Phone Number</label>
                        <div>{{store.phone}}</div>
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="form-label fw-bold">Preferred Email</label>
                        <div>{{store.email}}</div>
                    </div>
                    <div class="col-12 col-md-12">
                        <label class="form-label fw-bold">Website</label>
                        <div>{{store.website}}</div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="mb-3">
                            <label class="form-label fw-bold">Do you offer repairs?</label>
                            <div>{{store.provides_repairs ? 'yes' : 'no'}}</div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div>
                            <label class="form-label fw-bold">Repair Devices</label>
                            <ol>
                              <li v-for="device in store.repair_devices" :key="device.id">
                                {{ device.title }} ({{ device.pivot.volume }})
                              </li>
                            </ol>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3">
                        <label class="form-label fw-bold"> Does this location sell devices? </label>
                        <div>{{store.provides_sells ? 'yes' : 'no'}}</div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div>
                        <label class="form-label fw-bold">Sell Devices</label>
                        <ol>
                          <li v-for="device in store.sell_devices" :key="device.id">
                            {{ device.title }} ({{ device.pivot.volume }})
                          </li>
                        </ol>
                      </div>
                    </div>
                </div>
              </div>
              <div class="col-2 col-sm-4 text-sm-end">
                <div class="dropdown no-arrow">
                  <button class="btn btn-sm btn-light dropdown-toggle" type="button" role="button" 
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a @click.prevent="popupModalUpdate = true" class="dropdown-item" href="javascript:void(0)" >
                      Update status </a>
                    </li>
                    <li>
                      <router-link :to="`/users/${store.partner_id}`" class="dropdown-item">
                      View partner </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row gy-2">
          <div class="col-lg-6">
            <div class="card">
              <div class="card-body">
                <h5 class="border-bottom pb-2">Store hours</h5>
                <div class="mb-3">
                  <div v-for="(hour, hIndex) in  store.hours" :key="hIndex" class="mb-2">
                    <div>{{$filters.getWeekdayName(hIndex)}}: <strong>{{$filters.format_time(hour.open)}}</strong> - <strong>{{$filters.format_time(hour.close)}}</strong></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="store.location" class="col-lg-6">
            <address-card :item="store.location" />
          </div>
        </div>
      </div>
      <is-loading v-else />
    </div>
  </div>
  <b-modal hide-footer v-model="popupModalUpdate" title="Update store">
    <store-form v-if="popupModalUpdate" @closeMe="initializeAll()"
      :editItem="store" @updateItem="updateItem($event)"/>
  </b-modal>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import StoreForm from '@/components/forms/StoreForm.vue'
import AddressCard from '@/components/cards/AddressCard.vue'

export default {
  components: { IsLoading, StoreForm, AddressCard },
  data(){
    return{
      isLoading: false,
      store: null,
      popupModalUpdate: false,
    }
  },
  methods:{
    updateItem(item){
      Object.assign(this.store, item)
    },
    fetchStore(){
      this.isLoading = true
      this.$http.get(`/stores/${this.$route.params.storeId}/show`)
      .then((response) => {
        this.isLoading =  false
        if(response.data.success){
          this.store = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    initializeAll(){
      this.popupModalUpdate = false;
    },
  },
  created(){
    this.fetchStore()
  }
}
</script>

<style>

</style>